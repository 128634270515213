import * as React from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import './TabsProducts.css'
import ModalImage from "react-modal-image";
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useParams } from "react-router-dom";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    let { language, categoryId, productId } = useParams();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function TabsProducts(product) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="tabs">
                {/* <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" scrollButtons="auto">
                            <Tab label="Descrição" value="1" className="colors"/>
                            <Tab label="Ficha Técnica" value="2" className="colors"/>
                            <Tab label="Acessórios" value="3" className="colors"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <p>O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.</p> 
                        <p>Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.</p>
                        <p>Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial. </p></TabPanel>
                    <TabPanel value="2">
                        <ModalImage
                            small= {require('./../static/images/fichatecnica.jpg')}
                            medium={require('./../static/images/fichatecnica.jpg')}
                        />
                    </TabPanel>
                    <TabPanel value="3">
                        <ModalImage
                            small= {require('./../static/images/acessorios.jpg')}
                            medium={require('./../static/images/acessorios.jpg')}
                        />
                    </TabPanel>
                </TabContext> */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile>
                        <Tab className="colors" label="Descrição" {...a11yProps(0)} />
                        <Tab className="colors" label="Ficha Técnica" {...a11yProps(1)} />
                        {
                            product.product.categoryId === "1" ?
                            <Tab className="colors" label="Acessórios" {...a11yProps(2)} /> : null
                        }
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {product.product.description.map(desc => 
                            <p>{desc}</p>
                        )}
                    </Typography>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ModalImage
                        small={require(`./../static/images/${product.product.technicalinformationimg}`)}
                        medium={require(`./../static/images/${product.product.technicalinformationimg}`)}
                    />
                </CustomTabPanel>
                {
                    product.product.categoryId === "1" ?
                    <CustomTabPanel value={value} index={2}>
                        <ModalImage
                            small={require('./../static/images/acessorios.jpg')}
                            medium={require('./../static/images/acessorios.jpg')}
                        />
                    </CustomTabPanel> :
                    null
                }
            </div >
        </>
    )
}

export default TabsProducts;