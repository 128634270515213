let products = [
    {
        id: "2201",
        name: "Vinílico SPC K Nobre 2201",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2201/2201_1.jpg",
            "2201/2201_2.jpg",
            "2201/2201_3.jpg",
            "2201/2201_4.jpg",
            "2201/2201_cor.jpg"
        ],
        stock: 0,
        reference: "2201",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "2202",
        name: "Vinílico SPC K Nobre 2202",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2202/2202_1.jpg",
            "2202/2202_2.jpg",
            "2202/2202_3.jpg",
            "2202/2202_4.jpg",
            "2202/2202_cor.jpg"
        ],
        stock: 0,
        reference: "2202",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "2203",
        name: "Vinílico SPC K Nobre 2203",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2203/2203_1.jpg",
            "2203/2203_2.jpg",
            "2203/2203_3.jpg",
            "2203/2203_3.jpg",
            "2203/2203_cor.jpg"
        ],
        stock: 0,
        reference: "2203",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "2204",
        name: "Vinílico SPC K Nobre 2204",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2204/2204_1.jpg",
            "2204/2204_2.jpg",
            "2204/2204_3.jpg",
            "2204/2204_4.jpg",
            "2204/2204_cor.jpg"
        ],
        stock: 6,
        reference: "2204",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "2205",
        name: "Vinílico SPC K Nobre 2205",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2205/2205_1.jpg",
            "2205/2205_2.jpg",
            "2205/2205_3.jpg",
            "2205/2205_4.jpg",
            "2205/2205_cor.jpg"
        ],
        stock: 6,
        reference: "2205",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "2206",
        name: "Vinílico SPC K Nobre 2206",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2206/2206_1.jpg",
            "2206/2206_2.jpg",
            "2206/2206_3.jpg",
            "2206/2206_cor.jpg"
        ],
        stock: 6,
        reference: "2206",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "2212",
        name: "Vinílico SPC K Nobre 2212",
        description: [
            "O flutuante Vinílico SPC é composto por pedra, plástico e puro PVC o que torna o pavimento resistente e durável.",
            "Tem um comportamento notável face à humidade sendo 100% resistente, podendo por isso ser aplicado em cozinhas, casas de banho e lavandarias.",
            "Com uma espessura total de 7mm, incorporado com 2mm de tela IXPE que produz uma redução acústica de ruído de 19db, não necessita de tela underlay extra ou espuma adicional. Tendo uma camada de desgaste de 0.5 ( alto tráfego) e estando apto para piso radiante, tem ainda uma impressionante garantia de 20 anos para uso residencial e de 10 anos para uso comercial."
        ],
        categoryId: "1",
        categoryName: "Vinílicos SPC",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "2212/2212_1.jpg",
            "2212/2212_2.jpg",
            "2212/2212_3.jpg",
            "2212/2212_4.jpg"
        ],
        stock: 6,
        reference: "2212",
        online: true,
        createdate: "data",
        technicalinformationimg: "fichatecnica.jpg",
        properties:[
            {
                name: "Espessura Total:",
                value: "7 mm"
            },
            {
                name: "Camada de desgaste:",
                value: "0.5 mm"
            },
            {
                name: "Resistência à humidade",
                value: null
            },
            {
                name: "Material 100% virgem",
                value: null
            },
            {
                name: "Compatível com piso radiante",
                value: null
            },
            {
                name: "19db acústico",
                value: null
            }
        ]
    },
    {
        id: "B258",
        name: "Painél Acustico K Nobre B258",
        description: [
            "Painel ripado interior revestido com feltro acústico, eficaz na redução de ruídos e na  quebra de ondas sonoras. Ideal para ambientes comerciais e residenciais. Disponível  em várias cores e de fácil instalação, garantindo conforto acústico com um design  moderno e discreto."
        ],
        categoryId: "3",
        categoryName: "Painéis Interior",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "paineis/interior/B258/B258_1.jpg",
            "paineis/interior/B258/B258_2.jpg",
            "paineis/interior/B258/B258_3.jpg",
            "paineis/interior/B258/B258_5.jpg",
            "paineis/interior/B258/B258_4.jpg",
        ],
        stock: 6,
        reference: "B258",
        online: true,
        createdate: "data",
        technicalinformationimg: "paineis/interior/fichatecnica_painel_interior.jpg",
        properties:[
            {
                name: "Comprimento:",
                value: "2600 e 2900mm"
            },
            {
                name: "Largura:",
                value: "600mm"
            },
            {
                name: "Feltro Acústico de 10mm espessura",
                value: null
            },
            {
                name: "Coeficiente de absorção:",
                value: "0.3"
            },
            {
                name: "Resistente ao fogo",
                value: null
            }
        ]
    },
    {
        id: "B418",
        name: "Painél Acustico K Nobre B418",
        description: [
            "Painel ripado interior revestido com feltro acústico, eficaz na redução de ruídos e na  quebra de ondas sonoras. Ideal para ambientes comerciais e residenciais. Disponível  em várias cores e de fácil instalação, garantindo conforto acústico com um design  moderno e discreto."
        ],
        categoryId: "3",
        categoryName: "Painéis Interior",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "paineis/interior/B418/B418_1.jpg",
            "paineis/interior/B418/B418_2.jpg",
            "paineis/interior/B418/B418_3.jpg",
            "paineis/interior/B418/B418_5.jpg",
            "paineis/interior/B418/B418_4.jpg",
        ],
        stock: 6,
        reference: "B418",
        online: true,
        createdate: "data",
        technicalinformationimg: "paineis/interior/fichatecnica_painel_interior.jpg",
        properties:[
            {
                name: "Comprimento:",
                value: "2600 e 2900mm"
            },
            {
                name: "Largura:",
                value: "600mm"
            },
            {
                name: "Feltro Acústico de 10mm espessura",
                value: null
            },
            {
                name: "Coeficiente de absorção:",
                value: "0.3"
            },
            {
                name: "Resistente ao fogo",
                value: null
            }
        ]
    },
    {
        id: "B489",
        name: "Painél Acustico K Nobre B489",
        description: [
            "Painel ripado interior revestido com feltro acústico, eficaz na redução de ruídos e na  quebra de ondas sonoras. Ideal para ambientes comerciais e residenciais. Disponível  em várias cores e de fácil instalação, garantindo conforto acústico com um design  moderno e discreto."
        ],
        categoryId: "3",
        categoryName: "Painéis Interior",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "paineis/interior/B489/B489_1.jpg",
            "paineis/interior/B489/B489_2.jpg",
            "paineis/interior/B489/B489_3.jpg",
        ],
        stock: 6,
        reference: "B489",
        online: true,
        createdate: "data",
        technicalinformationimg: "paineis/interior/fichatecnica_painel_interior.jpg",
        properties:[
            {
                name: "Comprimento:",
                value: "2600 e 2900mm"
            },
            {
                name: "Largura:",
                value: "600mm"
            },
            {
                name: "Feltro Acústico de 10mm espessura",
                value: null
            },
            {
                name: "Coeficiente de absorção:",
                value: "0.3"
            },
            {
                name: "Resistente ao fogo",
                value: null
            }
        ]
    },
    {
        id: "B490",
        name: "Painél Acustico K Nobre B490",
        description: [
            "Painel ripado interior revestido com feltro acústico, eficaz na redução de ruídos e na  quebra de ondas sonoras. Ideal para ambientes comerciais e residenciais. Disponível  em várias cores e de fácil instalação, garantindo conforto acústico com um design  moderno e discreto."
        ],
        categoryId: "3",
        categoryName: "Painéis Interior",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "paineis/interior/B490/B490_1.jpg",
            "paineis/interior/B490/B490_2.jpg",
            "paineis/interior/B490/B490_3.jpg",
            "paineis/interior/B490/B490_4.jpg",
            "paineis/interior/B490/B490_5.jpg",
        ],
        stock: 6,
        reference: "B490",
        online: true,
        createdate: "data",
        technicalinformationimg: "paineis/interior/fichatecnica_painel_interior.jpg",
        properties:[
            {
                name: "Comprimento:",
                value: "2600 e 2900mm"
            },
            {
                name: "Largura:",
                value: "600mm"
            },
            {
                name: "Feltro Acústico de 10mm espessura",
                value: null
            },
            {
                name: "Coeficiente de absorção:",
                value: "0.3"
            },
            {
                name: "Resistente ao fogo",
                value: null
            }
        ]
    },
    {
        id: "1000",
        name: "Painel Exterior WPC",
        description: [
            "Painel Exterior WPC para fachadas, resistente às intempéries e não necessita de manutenção. Ideal para revestir exteriores, oferecendo durabilidade e um acabamento elegante. Disponível  em várias cores, proporciona um design moderno e sofisticado, com alta resistência à água, sol  e desgaste."
        ],
        categoryId: "4",
        categoryName: "Painéis Exterior",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "paineis/exterior/WPC/WPC_1.jpg",
            "paineis/exterior/WPC/WPC_2.jpg"
        ],
        stock: 6,
        reference: "10000",
        online: true,
        createdate: "data",
        technicalinformationimg: "paineis/exterior/fichatecnica_wpc.jpg",
        properties:[
            {
                name: "Comprimento:",
                value: " 2400, 2700 e 2900mm"
            },
            {
                name: "Largura:",
                value: "219mm"
            },
            {
                name: "WPC Compósito",
                value: null
            },
            {
                name: "Não necessita manutenção",
                value: null
            },
            {
                name: "Isolante e resistente a raios UV",
                value: null
            }
        ]
    },
    {
        id: "E411",
        name: "Multicamadas Carvalho Natural",
        description: [
            "O Flutuante Carvalho natural é uma opção elegante e sofisticada para quem procura durabilidade e beleza nos seus espaços.",
            "A partir de madeira de carvalho Europeu de alta qualidade, a sua superfície mantém a textura e os veios naturais da madeira.",
            "Com tábuas de comprimento único de 1900mm e com uma espessura de 15mm incluindo 3mm de madeira nobre, o flutuante Carvalho tem um tratamento escovado e acabamento em verniz mate, ideal para diversos tipos de ambientes residenciais e comerciais."
        ],
        categoryId: "2",
        categoryName: "Multicamadas",
        brandId: "string",
        brandName: "K.nobre",
        images: [
            "flutuantes/multicamadas/Carvalho/carvalho_1.jpg",
            "flutuantes/multicamadas/Carvalho/carvalho_2.jpg",
            "flutuantes/multicamadas/Carvalho/carvalho_3.jpg",
            "flutuantes/multicamadas/Carvalho/carvalho_4.jpg",
            "flutuantes/multicamadas/Carvalho/carvalho_5.jpg",
            "flutuantes/multicamadas/Carvalho/carvalho_6.jpg",
        ],
        stock: 6,
        reference: "E411",
        online: true,
        createdate: "data",
        technicalinformationimg: "flutuantes/multicamadas/Carvalho/FichaTécnica_Multicamadas.jpg",
        properties:[
            {
                name: "Comprimento:",
                value: "1900mm"
            },
            {
                name: "Largura:",
                value: "190mm"
            },
            {
                name: "Espessura total:",
                value: "15mm"
            },
            {
                name: "Madeira Nobre:",
                value: "3mm"
            },
            {
                name: "Contraplacado:",
                value: "100% bétula"
            },
            {
                name: "Acabamento:",
                value: "Verniz Escovado"
            }
        ]
    }
]

export default products;